import { CaseGanttCase, CaseGanttSetting } from "./ApiTypes";
import { SessionType } from "../contexts/SessionContext";
import { SettingsContextType } from "../contexts/SettingsContext";
import { Formatter } from "../utils/Formatter";

export const CaseGanttOutputLabels = [
    { setting: CaseGanttSetting.Duration, label: "common.throughputTime" },
    { setting: CaseGanttSetting.Yield, label: "common.yield" },
    { setting: CaseGanttSetting.YieldRate, label: "output.rate" },
];

export enum CaseGanttStat {
    Duration = "duration",
    YieldCount = "yieldCount",
    YieldRateCount = "yieldRateCount",
    YieldMass = "yieldMass",
    YieldRateMass = "yieldRateMass",
    YieldLength = "yieldLength",
    YieldRateLength = "yieldRateLength",
    StartTime = "startTime",
}

export const CaseGanttStats = [
    {
        statType: CaseGanttStat.Duration,
        label: "common.throughputTime",
    }, {
        statType: CaseGanttStat.StartTime,
        label: "common.throughputTime",
    }, {
        statType: CaseGanttStat.YieldCount,
        baseQuantity: "count",
        settings: CaseGanttSetting.Yield,
        label: "output.yieldCount",
        requiredEventKey: "caseYieldCount"
    }, {
        statType: CaseGanttStat.YieldRateCount,
        baseQuantity: "count",
        settings: CaseGanttSetting.YieldRate,
        label: "output.yieldRateCount",
        requiredEventKey: "caseYieldCount"
    }, {
        statType: CaseGanttStat.YieldMass,
        baseQuantity: "mass",
        settings: CaseGanttSetting.Yield,
        label: "output.yieldMass",
        requiredEventKey: "caseYieldMass"
    }, {
        statType: CaseGanttStat.YieldRateMass,
        baseQuantity: "mass",
        settings: CaseGanttSetting.YieldRate,
        label: "output.yieldRateMass",
        requiredEventKey: "caseYieldMass"
    }, {
        statType: CaseGanttStat.YieldLength,
        baseQuantity: "length",
        settings: CaseGanttSetting.Yield,
        label: "output.yieldLength",
        requiredEventKey: "caseYieldLength"
    }, {
        statType: CaseGanttStat.YieldRateLength,
        baseQuantity: "length",
        settings: CaseGanttSetting.YieldRate,
        label: "output.yieldRateLength",
        requiredEventKey: "caseYieldLength"
    },
];

export function formatCaseStat(entry: CaseGanttCase, caseStat: CaseGanttStat, session: SessionType) {
    switch (caseStat) {
        case CaseGanttStat.StartTime:
        case CaseGanttStat.Duration:
            return Formatter.formatDurationShort(entry.caseDuration, undefined, session.numberFormatLocale);
        case CaseGanttStat.YieldCount:
            return Formatter.formatNumber(entry.caseYieldCount, 2, session.numberFormatLocale);
        case CaseGanttStat.YieldRateCount:
            return Formatter.formatCountFlow(entry.caseYieldRateCount, undefined, session.numberFormatLocale);
        case CaseGanttStat.YieldMass:
            return Formatter.formatWeight(entry.caseYieldMass, undefined, session.numberFormatLocale);
        case CaseGanttStat.YieldRateMass:
            return Formatter.formatMassFlow(entry.caseYieldRateMass, undefined, session.numberFormatLocale);
        case CaseGanttStat.YieldLength:
            return Formatter.formatMetricLength(entry.caseYieldLength, undefined, session.numberFormatLocale);
        case CaseGanttStat.YieldRateLength:
            return Formatter.formatSpeed(entry.caseYieldRateLength, undefined, session.numberFormatLocale);
        default:
            break;
    }
}

export function getStatType(settings: SettingsContextType) {
    switch (settings.gantt.caseGanttSettings) {
        case CaseGanttSetting.Duration:
            return CaseGanttStat.Duration;
        case CaseGanttSetting.StartTime:
            return CaseGanttStat.StartTime;
        case CaseGanttSetting.Yield:
        case CaseGanttSetting.YieldRate:
            return CaseGanttStats.find((c) => c.baseQuantity === settings.quantity && c.settings === settings.gantt.caseGanttSettings)?.statType ?? CaseGanttStat.Duration;
    }
}
